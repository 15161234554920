import { Dispatch, SetStateAction } from "react";

import {
  FooterItemsLabelsEnum,
  GlobalRouteEnums,
} from "@/TS/Enums/global_enums";
import { FooterItems } from "@/TS/Interfaces/global_interfaces";

export const footerItemsTwo: FooterItems[] = [
  { label: FooterItemsLabelsEnum.HOME, href: GlobalRouteEnums.HOME },
  {
    label: FooterItemsLabelsEnum.CLUBS_CONCEPTS,
    href: GlobalRouteEnums.CLUBS_CONCEPTS,
  },
  {
    label: FooterItemsLabelsEnum.JOIN_CLUB,
    href: GlobalRouteEnums.JOIN_CLUB,
    // callback: (setter: Dispatch<SetStateAction<boolean>>): void => {
    //   setter(true);
    // },
  },
  {
    label: FooterItemsLabelsEnum.CREATE_CLUB,
    href: GlobalRouteEnums.CREATE_CLUB,
  },
  {
    label: FooterItemsLabelsEnum.TESTIMONIALS,
    href: GlobalRouteEnums.TESTIMONIALS,
  },
  {
    label: FooterItemsLabelsEnum.DYNABUY_NETWORK,
    href: GlobalRouteEnums.DYNABUY_NETWORK,
  },
  { label: FooterItemsLabelsEnum.CONNEXION, href: GlobalRouteEnums.CONNEXION },
];

export const footerArrayOne: {
  label: string;
  href?: string;
  callback?: any;
  download?: string;
}[] = [
  // {
  //   label: FooterItemsLabelsEnum.CONTACT,
  //   href: "/",
  //   callback: (ref, ...args) => {
  //     setTimeout(() => {
  //       ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
  //     }, 100);
  //   },
  // },
  {
    label: FooterItemsLabelsEnum.LEGAL_NOTICES,
    href: GlobalRouteEnums.LEGAL_NOTICES,
  },
  {
    label: FooterItemsLabelsEnum.PRIVACY_AND_COOKIES_POLICY,
    href: GlobalRouteEnums.PRIVACY_AND_COOKIES_POLICY,
    download: "politique-confidentialite.pdf",
  },
  { label: FooterItemsLabelsEnum.CGU, href: GlobalRouteEnums.CGU },
];
