"use client";

import Link from "next/link";
import { useRouter } from "next/navigation";
import React, { useContext } from "react";
import { callbackContext } from "@/context/callback-context-provider";
import { FooterLineProps } from "@/TS/Interfaces/global_interfaces";
function FooterLine({
  stringArr
}: FooterLineProps) {
  const {
    setCallback,
    setId,
    needReload,
    setNeedReload
  } = useContext(callbackContext);
  const linkFooter = (label, callback, href) => {
    if (callback) {
      return <button onClick={e => {
        if (needReload) {
          setId(label);
          setCallback(() => callback);
          return;
        }
        setId(label);
        setCallback(() => callback);
        e.preventDefault();
      }}>
          {label}
        </button>;
    } else {
      return <Link href={href ?? "/"}>{label}</Link>;
    }
  };
  return <ul data-sentry-component="FooterLine" data-sentry-source-file="footerLine.tsx">
      {stringArr && stringArr.map(({
      label,
      href,
      callback
    }) => {
      return <li key={label} className={"mb-1"}>
              {linkFooter(label, callback, href)}
            </li>;
    })}
    </ul>;
}
export default FooterLine;