"use client";

import Image from "next/image";
import Link from "next/link";
import { SetStateAction, useContext } from "react";
import FooterLine from "@/components/global/footerLine";
import Icon from "@/components/icon";
import { footerArrayOne, footerItemsTwo } from "@/constants/footer";
import { MobileContext } from "@/context/mobile-provider";
import useMobile from "@/hooks/use-mobile";
export default function Footer(props) {
  const {
    isPublic,
    setIsPublic
  } = useContext(MobileContext);
  const isMobile = useMobile();
  return <footer style={!isPublic && isMobile ? {
    paddingBottom: "96px"
  } : null} className={"bg-secondary pt-4 text-dark-grey text-xs"} data-sentry-component="Footer" data-sentry-source-file="footer.tsx">
      <div className="container">
        <div className={"py-4 flex flex-col md:flex-row"}>
          <div className={"mb-2 md:mr-16"}>
            <span className={`text-white font-bold text-base mb-2 block`}>
              Vous informer
            </span>
            <FooterLine stringArr={footerArrayOne} data-sentry-element="FooterLine" data-sentry-source-file="footer.tsx"></FooterLine>
          </div>
          <div className={"mb-2 md:mr-16"}>
            <span className={`text-white font-bold text-base mb-2 block`}>
              Club d'affaires
            </span>
            <FooterLine stringArr={footerItemsTwo} data-sentry-element="FooterLine" data-sentry-source-file="footer.tsx"></FooterLine>
          </div>
          <div className={"mb-2 md:mr-16"}>
            <span className={`text-white font-bold text-base mb-2 block`}>
              Sites du groupe
            </span>
            <ul>
              <li className={"mb-1"}>
                <Link href={"https://www.dynabuy.fr/"} className={"hover:text-grey"} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  &gt; Dynabuy.fr
                </Link>
              </li>
              <li className={"mb-1"}>
                <Link href={"https://www.avantages-prives.com/"} className={"hover:text-grey"} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  &gt; Avantages-prives.com
                </Link>
              </li>
              <li className={"mb-1"}>
                <Link href={"https://www.avantages-entreprises.com/"} className={"hover:text-grey"} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  &gt; Avantages-entreprises.com
                </Link>
              </li>
            </ul>
          </div>
          <div className={"grow flex justify-between"}>
            <div className={"mb-2"}>
              <span className={`text-white font-bold text-base mb-2 block`}>
                Suivez-nous
              </span>
              <div>
                <Link href={"https://www.youtube.com/c/Dynabuy"} target={"_blank"} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  <Icon icon={"youtube"} className={`mr-2 bg-primary rounded-full text-white hover:bg-white hover:text-primary`} data-sentry-element="Icon" data-sentry-source-file="footer.tsx"></Icon>
                </Link>
                <Link href={"https://www.linkedin.com/company/dynabuyfr"} target={"_blank"} data-sentry-element="Link" data-sentry-source-file="footer.tsx">
                  <Icon icon={"linkedin"} className={`bg-primary rounded-full text-white hover:bg-white hover:text-primary`} data-sentry-element="Icon" data-sentry-source-file="footer.tsx"></Icon>
                </Link>
              </div>
            </div>
            <div className={"ml-auto"}>
              <Image src={"/img/Dynabuy-logo2.svg"} alt={"Dynabuy"} className={"w-52"} width={210} height={102} data-sentry-element="Image" data-sentry-source-file="footer.tsx" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-6">
        <div className={"border-t border-dark-grey text-dark-grey text-center pb-4 md:py-6"}>
          © 2024 Dynabuy. Tous droits réservés
        </div>
      </div>
    </footer>;
}