"use client";

import Link from "next/link";
import { useContext } from "react";
import { twMerge } from "tailwind-merge";
import { breadcrumbContext } from "@/context/breadcrumb-context";
import { BreadCrumbLink, BreadcrumbProps } from "@/TS/Interfaces/global_interfaces";
export default function Breadcrumb({
  className,
  crumb
}: BreadcrumbProps) {
  const [value, setValue] = useContext(breadcrumbContext);
  const handleClick = link => {
    const indexOf = value.findIndex(({
      href
    }) => {
      return href === link;
    });
    const newTab = value.slice(0, indexOf + 1);
    setValue(newTab);
  };
  return <>
      <div id={"bread"} className={twMerge("bg-light-grey px-12 xl:px-20 py-2 text-xs flex", className)}>
        <div>
          {crumb && crumb.map((href: BreadCrumbLink, index: number) => {
          if (index === crumb.length - 1) {
            return <span key={href.href}>
                    <Link onClick={() => handleClick(href.href)} key={href.href} className={"text-primary"} href={href.href}>
                      {href.label}{" "}
                    </Link>
                  </span>;
          } else return <span key={href.href}>
                    <Link onClick={() => handleClick(href.href)} key={href.href} className={`text-dark-grey`} href={href.href}>
                      {href.label}{" "}
                    </Link>
                  </span>;
        })}
        </div>
      </div>
    </>;
}