"use client";

import Link from "next/link";
import React, { ReactNode, useState } from "react";
import UserContact from "@/app/(main)/_user_components/user-contact";
import UserParameters from "@/app/(main)/_user_components/user-parameters";
import UserProfileEdit from "@/app/(main)/_user_components/user-profile-edit";
import UserProfileShow from "@/app/(main)/_user_components/user-profile-show";
import UserSidebar from "@/app/(main)/_user_components/user-sidebar";
import Footer from "@/app/(main)/footer";
import Header from "@/app/(main)/header";
import useProfile from "@/hooks/useProfile";
import CloseMobileNavWhenPathChange from "@/utils/close-mobile-nav-when-path-change";
export default function MainLayout({
  children
}: {
  children?: ReactNode;
}) {
  const [showUserSidebar, setShowUserSidebar] = useState<boolean | null>(false);
  const [showUserParameters, setShowUserParameters] = useState<boolean | null>(false);
  const [showUserProfileShow, setShowUserProfileShow] = useState<boolean | null>(false);
  const [showUserProfileEdit, setShowUserProfileEdit] = useState<boolean | null>(false);
  const [showUserContact, setShowUserContact] = useState<boolean | null>(false);
  return <>
      <CloseMobileNavWhenPathChange data-sentry-element="CloseMobileNavWhenPathChange" data-sentry-source-file="layout.tsx" />
      <Header setShowUserSidebar={setShowUserSidebar} data-sentry-element="Header" data-sentry-source-file="layout.tsx" />
      <div id={"container"} className={"grow min-h-full"}>
        {children}
      </div>
      <Footer data-sentry-element="Footer" data-sentry-source-file="layout.tsx" />
      {/*<UserSidebar*/}
      {/*  showUserSidebar={showUserSidebar}*/}
      {/*  setShowUserSidebar={setShowUserSidebar}*/}
      {/*  setShowUserParameters={setShowUserParameters}*/}
      {/*  setShowUserProfileShow={setShowUserProfileShow}*/}
      {/*  setShowUserProfileEdit={setShowUserProfileEdit}*/}
      {/*  setShowUserContact={setShowUserContact}*/}
      {/*/>*/}
      {/*<UserParameters*/}
      {/*  showUserParameters={showUserParameters}*/}
      {/*  setShowUserParameters={setShowUserParameters}*/}
      {/*/>*/}
      {/*{showUserProfileShow && (*/}
      {/*  <UserProfileShow*/}
      {/*    showUserProfileShow={showUserProfileShow}*/}
      {/*    setShowUserProfileShow={setShowUserProfileShow}*/}
      {/*    setShowUserProfileEdit={setShowUserProfileEdit}*/}
      {/*  />*/}
      {/*)}*/}
      {/*{showUserProfileEdit && (*/}
      {/*  <UserProfileEdit*/}
      {/*    showUserProfileEdit={showUserProfileEdit}*/}
      {/*    setShowUserProfileShow={setShowUserProfileShow}*/}
      {/*    setShowUserProfileEdit={setShowUserProfileEdit}*/}
      {/*  />*/}
      {/*)}*/}
      {/*<UserContact*/}
      {/*  showUserContact={showUserContact}*/}
      {/*  setShowUserContact={setShowUserContact}*/}
      {/*/>*/}
    </>;
}