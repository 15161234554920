"use client";

import React, { useContext, useEffect } from "react";
import Icon from "@/components/icon";
import { isBurgerActiveContext } from "@/context/is-burger-active";
function BurgerMenu(props) {
  const [isActive, setIsActive] = useContext(isBurgerActiveContext);

  // désactive le scroll de la page lorsque le menu burger est activé
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
    }
  }, [isActive]);
  return <div className={props.className} onClick={() => setIsActive(!isActive)} data-sentry-component="BurgerMenu" data-sentry-source-file="burger-menu.tsx">
      {!isActive ? <Icon size={25} icon={"menu"} /> : <Icon size={35} icon={"cross"} />}
    </div>;
}
export default BurgerMenu;